var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NavBar, Button } from '@s8/react-component-library';
import UserProfile from './UserProfile';
import OpenwealthLogo from './asset/openwealth_logo_dark.svg';
import { useEffect } from 'react';
import { useAuthUtils } from '../../../utils/authUtils';
export default function Header() {
    var _a = useAuthUtils(), user = _a.user, isAuthenticated = _a.isAuthenticated, login = _a.login, logout = _a.logout, addBroadcastChannelListeners = _a.addBroadcastChannelListeners;
    useEffect(function () {
        addBroadcastChannelListeners();
    }, []);
    var NavbarTabs = [
        { id: 'about', label: 'About', linkProps: { to: '/about' } },
        { id: 'docs', label: 'API Docs', linkProps: { to: '/docs' } },
        { id: 'use-cases', label: 'API Use Cases', linkProps: { to: '/use-cases' } },
    ];
    var startComponent = (_jsx("div", __assign({ className: "w-32" }, { children: _jsx("img", { className: "object-cover object-center", src: OpenwealthLogo }) })));
    var endComponent = isAuthenticated ? (_jsx("div", __assign({ className: "flex space-x-4" }, { children: _jsx(UserProfile, { user: {
                id: user === null || user === void 0 ? void 0 : user.profile.sub,
                firstName: user === null || user === void 0 ? void 0 : user.profile.given_name,
                lastName: user === null || user === void 0 ? void 0 : user.profile.family_name,
                email: user === null || user === void 0 ? void 0 : user.profile.email,
                accessToken: user === null || user === void 0 ? void 0 : user.access_token,
            }, logout: logout }) }))) : (_jsx(Button, __assign({ size: "medium", variant: "secondary", className: "border border-brand-base text-brand-base mx-4 bg-white", onClick: function () { return login(); } }, { children: "Login" })));
    return (_jsx(NavBar, { tabs: NavbarTabs, startProps: { component: startComponent }, endProps: { component: endComponent }, size: "small", position: "sticky" }));
}
