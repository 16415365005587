var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from '@s8/react-component-library';
export var LinkedinIcon = function () {
    return (_jsxs(SvgIcon, __assign({ size: "xlarge" }, { children: [_jsx("path", { d: "M26.5 4.5H5.5C4.94772 4.5 4.5 4.94772 4.5 5.5V26.5C4.5 27.0523 4.94772 27.5 5.5 27.5H26.5C27.0523 27.5 27.5 27.0523 27.5 26.5V5.5C27.5 4.94772 27.0523 4.5 26.5 4.5Z", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15 14V22", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11 14V22", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15 17.5C15 16.5717 15.3687 15.6815 16.0251 15.0251C16.6815 14.3687 17.5717 14 18.5 14C19.4283 14 20.3185 14.3687 20.9749 15.0251C21.6313 15.6815 22 16.5717 22 17.5V22", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11 11.5C11.8284 11.5 12.5 10.8284 12.5 10C12.5 9.17157 11.8284 8.5 11 8.5C10.1716 8.5 9.5 9.17157 9.5 10C9.5 10.8284 10.1716 11.5 11 11.5Z", fill: "white" })] })));
};
