var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { DOCS_LIST } from '../../../config';
import { DownloadIcon, Button, Dropdown, Link } from '@s8/react-component-library';
import { useAuth } from 'react-oidc-context';
import { resetState } from '../../../redux/thunks/UseCaseStatesService';
import { useAppDispatch } from '../../../redux/hook';
export default function DocUI(_a) {
    var docId = _a.docId;
    var docs = DOCS_LIST.docs, collection = DOCS_LIST.collection;
    var docToDisplay = docs.find(function (doc) {
        return docId === doc.id;
    });
    var dispatch = useAppDispatch();
    var auth = useAuth();
    var downloadOptionsDropDown = (_jsx(Dropdown, { options: {
            'Postman Collection': [
                {
                    id: 'postman_collection',
                    label: 'Postman Collection',
                    value: 'postman_collection',
                    component: (
                    // create a URL representing the blob created from the JSON object for download
                    _jsx(Link, __assign({ id: "postman-download", to: window.URL.createObjectURL(new Blob([JSON.stringify(collection, undefined, 2)], {
                            type: 'application/json',
                        })), download: "".concat(collection.info.name, " Postman Collection.json"), target: "_blank" }, { children: _jsx(Button, __assign({ size: "medium", variant: "primary", endIcon: _jsx(DownloadIcon, {}) }, { children: "Postman Collection" })) }))),
                },
            ],
            'API Specification YAML': [
                {
                    id: 'api_specification_yaml',
                    label: 'API Specification YAML',
                    value: 'api_specification_yaml',
                    component: (_jsx(Link, __assign({ id: "api-download", to: docToDisplay.download, download: "".concat(docToDisplay.item.info.title, ".yaml"), target: "_blank" }, { children: _jsx(Button, __assign({ size: "medium", variant: "primary", endIcon: _jsx(DownloadIcon, {}) }, { children: "API Specification YAML" })) }))),
                },
            ],
        }, size: "medium", menuPlacement: "bottom-start", closeOnSelect: false, menuWidth: 250, primaryButtonProps: {
            actionType: 'default',
            variant: 'primary',
            className: 'bg-gradient-coral text-white',
        }, title: "Download Options" }));
    if (auth.isAuthenticated) {
        // the user must reset their state before using the Swagger UI for requests
        dispatch(resetState({ accessToken: auth.user.access_token }));
    }
    return (_jsx("div", __assign({ className: "-mt-7 ml-8" }, { children: docToDisplay && (_jsxs("div", { children: [_jsx("div", __assign({ className: "float-right mr-6" }, { children: downloadOptionsDropDown })), _jsx(SwaggerUI, { spec: docToDisplay.item })] })) })));
}
