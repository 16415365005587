var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { fetchUseCases } from '../thunks/UseCasesService';
var initialState = {
    status: 'idle',
    error: null,
    data: [],
};
export var useCasesSlice = createSlice({
    name: 'useCases',
    initialState: initialState,
    reducers: {
    // ...
    },
    extraReducers: function (builder) {
        // ------- fetching use cases -------
        builder.addCase(fetchUseCases.pending, function (state) {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(fetchUseCases.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = 'success';
            state.data = __spreadArray([], payload, true); // add the returned use cases to the list
        });
        builder.addCase(fetchUseCases.rejected, function (state, _a) {
            var payload = _a.payload;
            if (payload)
                state.error = payload.message;
            state.status = 'failed';
        });
    },
});
export var selectUseCasesStatus = function (state) { return state.useCases.status; };
export var selectUseCasesError = function (state) { return state.useCases.error; };
export var selectUseCases = function (state) { return state.useCases.data; };
export default useCasesSlice.reducer;
