var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, IconButton, UserIcon, Avatar, SignoutIcon, Input, } from '@s8/react-component-library';
import { CopyIcon } from '../../Icons';
var UserProfile = function (_a) {
    var user = _a.user, logout = _a.logout;
    var id = user.id, firstName = user.firstName, lastName = user.lastName, email = user.email, accessToken = user.accessToken;
    var fullName = "".concat(firstName, " ").concat(lastName);
    var getInitials = function () {
        var names = [firstName, lastName];
        return names
            .map(function (i) { return (i ? i.charAt(0) : ''); })
            .join('')
            .toUpperCase();
    };
    var initials = getInitials();
    var userProfileMenu = (_jsx(Dropdown, { buttonComponent: _jsx(IconButton, { children: _jsx(Avatar, { altText: initials, type: "text" }) }), options: {
            Account: [
                {
                    description: email,
                    icon: _jsx(UserIcon, {}),
                    id: id,
                    label: fullName,
                    value: initials,
                },
            ],
            'Access Token': [
                {
                    id: 'access_token',
                    label: 'Access token',
                    value: 'access_token',
                    component: (_jsx(Input, { id: "access_token", size: "medium", value: accessToken, readOnly: true, endIcon: _jsx(CopyIcon, { style: "h-4 w-4", textToCopy: accessToken }) })),
                },
                {
                    action: function () { return logout(); },
                    component: (_jsxs(_Fragment, { children: [_jsx(SignoutIcon, {}), " Log out"] })),
                    id: 'logout',
                    label: 'Log out',
                    value: 'logout',
                },
            ],
        }, showSectionHeaders: true, size: "medium", menuPlacement: "bottom-start", menuWidth: 250, closeOnSelect: false }));
    return _jsx("div", __assign({ className: "bg-inherit px-4" }, { children: userProfileMenu }));
};
export default UserProfile;
