var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { CopyIcon } from '../../../../UI/Icons';
export default function Input(_a) {
    var label = _a.label, value = _a.value, style = _a.style, copyIcon = _a.copyIcon;
    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("div", __assign({ className: "text-brand-base text-heading-7" }, { children: label })), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", __assign({ title: value, className: clsx('text-body-2 border border-brand-base border-t-0 border-x-0 border-b-1 pt-0 px-0 py-0.5 whitespace-nowrap truncate', style) }, { children: value })), copyIcon ? (_jsx(CopyIcon, { style: "w-6 h-6 mx-2 text-brand-base", textToCopy: value })) : undefined] }))] })));
}
