var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@s8/react-component-library';
import OpenWealthSandboxLogo from './asset/openwealth_sandbox_logo.png';
import DocsList from '../../components/UI/DocsList';
export default function About() {
    document.title = 'OpenWealth API Sandbox';
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "flex justify-center mt-28 xl:gap-x-64" }, { children: [_jsxs("div", __assign({ className: "space-y-8" }, { children: [_jsxs("div", __assign({ className: "text-heading-1" }, { children: [_jsx("p", { children: "Open API Standard" }), _jsx("p", { children: "for the Global Wealth" }), _jsx("p", { children: "Management Community" })] })), _jsxs("div", __assign({ className: "text-body-1 text-tertiary" }, { children: [_jsx("p", { children: "Connecting Financial Institutions," }), _jsx("p", { children: "WealthTechs and other Service Providers" })] })), _jsx(Button, __assign({ type: "button", size: "large", className: "bg-gradient-coral", actionType: "default", variant: "primary", linkProps: { to: '/docs/quick-start' } }, { children: "Get started" }))] })), _jsx("div", __assign({ className: "w-fit" }, { children: _jsx("img", { className: "object-cover object-center", src: OpenWealthSandboxLogo }) }))] })), _jsxs("div", __assign({ className: "flex flex-col xl:items-center mt-20" }, { children: [_jsx("div", __assign({ className: "text-heading-2 text-center" }, { children: "Browse OpenWealth APIs" })), _jsx(DocsList, { style: "gap-x-14 mt-16 mb-40", itemWidth: "w-[400px]" })] }))] }));
}
