var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardContent, CardHeader, ImageContainer, Popup, } from '@s8/react-component-library';
import { useState } from 'react';
export default function QuickStartItem(props) {
    var header = props.header, description = props.description, image = props.image, steps = props.steps;
    var _a = useState(false), showPopup = _a[0], togglePopup = _a[1];
    var handleClick = function () {
        togglePopup(function (showPopup) { return !showPopup; });
    };
    var renderedSteps = steps.map(function (step) {
        return (_jsxs("div", __assign({ className: "grid grid-cols-2 mb-4 !text-body-1" }, { children: [_jsx(ImageContainer, { className: "w-[414px]", src: step.image, aspectRatio: "16/9" }), _jsxs("div", __assign({ className: "flex flex-col space-y-3 -ml-28 px-4" }, { children: [_jsx("p", __assign({ className: "text-heading-5" }, { children: step.header })), _jsx("div", __assign({ className: "text-secondary text-body-1" }, { children: step.description }))] }))] }), step.id));
    });
    var popupBody = _jsx("div", { children: renderedSteps });
    return (_jsxs("div", { children: [_jsxs(Card, __assign({ className: "w-[500px] h-[200px]" }, { children: [_jsx(CardHeader, { action: _jsxs(Button, __assign({ className: "text-link text-link-md flex space-x-1", onClick: handleClick }, { children: ["View More ", '>'] })), divider: true, header: header, size: "large" }), _jsxs(CardContent, __assign({ className: "grid grid-cols-3" }, { children: [_jsx(ImageContainer, { className: "w-[90px]", src: image, aspectRatio: "1/1" }), _jsx("p", __assign({ className: "col-span-2 text-secondary" }, { children: description }))] }))] })), _jsx(Popup, { closeButton: true, header: header, headerClass: "!text-heading-5", size: "small", body: popupBody, width: 1138, minWidth: 1138, maxHeight: '80vh', showPopup: showPopup, togglePopup: handleClick })] }));
}
