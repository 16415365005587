import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { highlight, languages } from 'prismjs';
import Editor from 'react-simple-code-editor';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-java';
import './style/prism.css';
export default function CodeEditor(_a) {
    var code = _a.code, language = _a.language, _b = _a.width, width = _b === void 0 ? 800 : _b, _c = _a.height, height = _c === void 0 ? 400 : _c, _d = _a.backgroundColor, backgroundColor = _d === void 0 ? '#f6f8fa' : _d, _e = _a.fontSize, fontSize = _e === void 0 ? 12 : _e, _f = _a.readOnly, readOnly = _f === void 0 ? false : _f;
    var _g = useState(code), content = _g[0], setContent = _g[1];
    useEffect(function () {
        setContent(code);
    }, [code]);
    return (_jsx(Editor, { value: content, onValueChange: function (code) {
            if (!readOnly) {
                setContent(code);
            }
        }, highlight: function (code) { return highlight(content, languages[language], language); }, padding: 10, className: "styled-scrollbar", style: {
            fontFamily: '"Fira code", "Fira Mono", monospace',
            overflow: 'auto',
            fontSize: fontSize,
            width: width,
            height: height,
            backgroundColor: backgroundColor,
        } }));
}
