import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from 'react-oidc-context';
import Loading from '../../components/UI/Loading';
import { useEffect } from 'react';
import { redirectToOrigin } from '../../utils/authUtils';
export default function LoginRedirect() {
    var _a = useAuth(), isLoading = _a.isLoading, isAuthenticated = _a.isAuthenticated;
    useEffect(function () {
        if (!isLoading && !isAuthenticated) {
            redirectToOrigin();
        }
    }, [isLoading, isAuthenticated]);
    return (_jsx(Loading, { header: !isAuthenticated ? 'Login in progress' : 'Login successful', subtitle: "Hold tight, we're redirecting you to the previous page." }));
}
