var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import orderPlacementJSON from './assets/order_placement_2.0.8_swagger.json';
import orderPlacementYaml from './assets/order_placement_2.0.8_swagger.yaml';
import orderPlacementImage from './assets/order_placement.png';
import customerManagementJSON from './assets/customer_management_2.0.6_swagger.json';
import customerManagementYaml from './assets/customer_management_2.0.6_swagger.yaml';
import customerManagementImage from './assets/customer_management.png';
import custodyServicesJSON from './assets/custody_services_2.0.3_swagger.json';
import custodyServicesYaml from './assets/custody_services_2.0.3_swagger.yaml';
import custodyServicesImage from './assets/custody_services.png';
import postmanCollection from './assets/openwealth_sandbox_postman_collection.json';
import swaggerIcon from './assets/swagger_icon.png';
import useCaseJourneyIcon from './assets/use_case_journey_icon.png';
import postmanIcon from './assets/postman_icon.png';
import appIcon from './assets/app_icon.png';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { redirectToOrigin } from '../utils/authUtils';
import { DownloadIcon, Link } from '@s8/react-component-library';
export var HOME_PAGE_PATH = '/about';
// add the environment appropriate values to the postman collection file
postmanCollection.variable.find(function (variable) {
    if (variable.key === 'keycloak_realm') {
        variable.value = process.env.KEYCLOAK_REALM;
    }
    if (variable.key === 'openwealth_uri') {
        variable.value = window.location.origin;
    }
    if (variable.key === 'openwealth_be_uri') {
        variable.value = process.env.API_URL;
    }
});
export var DOCS_LIST = {
    docs: [
        {
            id: 'order-placement',
            item: __assign(__assign({}, orderPlacementJSON), { servers: [
                    __assign(__assign({}, orderPlacementJSON['servers'][0]), { url: "".concat(process.env.API_URL, "/api/order-placement/v2") }),
                ] }),
            image: orderPlacementImage,
            download: orderPlacementYaml,
        },
        {
            id: 'customer-management',
            item: __assign(__assign({}, customerManagementJSON), { servers: [
                    __assign(__assign({}, customerManagementJSON['servers'][0]), { url: "".concat(process.env.API_URL, "/api/customer-management/v2/") }),
                ] }),
            image: customerManagementImage,
            download: customerManagementYaml,
        },
        {
            id: 'custody-services',
            item: __assign(__assign({}, custodyServicesJSON), { servers: [
                    __assign(__assign({}, custodyServicesJSON['servers'][0]), { url: "".concat(process.env.API_URL, "/api/custody-services/v2/") }),
                ] }),
            image: custodyServicesImage,
            download: custodyServicesYaml,
        },
    ],
    collection: postmanCollection,
};
var getStepImage = function (id, stepId) {
    try {
        var image = require("./assets/".concat(id, "_step_").concat(stepId, ".png"));
        return image;
    }
    catch (error) {
        return '';
    }
};
var collection = DOCS_LIST.collection;
export var QUICK_START_LIST = [
    {
        id: 'swagger',
        header: 'Test OpenWealth API via Swagger UI',
        description: 'You can review the OpenWealth API documentation and experiment with it directly using the integrated Swagger UI.',
        image: swaggerIcon,
        steps: [
            {
                id: '1',
                header: 'Step 1: Open Your Profile to Generate Access Token',
                description: "After logging in, locate your profile icon in the upper right corner. Access your profile and click on the 'Generate Access Token' button to obtain your user access token.",
                image: getStepImage('swagger', '1'),
            },
            {
                id: '2',
                header: 'Step 2: Copy and Paste the Access Token to the Swagger UI',
                description: (_jsxs("div", { children: [_jsx("p", { children: "On Swagger UI, authorize yourself by copying your access token and pasting it into the designated area." }), _jsx("p", __assign({ className: "mt-8" }, { children: "If you encounter response code 401, it indicates that your access token has expired. To resolve this, please obtain a new access token and paste it into the authorization pop-up within the Swagger UI." }))] })),
                image: getStepImage('swagger', '2'),
            },
        ],
    },
    {
        id: 'use_case_journey',
        header: 'Guided Use Case Journey',
        description: 'With a thoughtfully designed use case, you can unlock a range of capabilities offered by OpenWealth API.',
        image: useCaseJourneyIcon,
        steps: [
            {
                id: '1',
                header: 'Step 1:  Access the API Use Case Overview',
                description: "Select 'API Use Cases' on the navigation bar. This will take you to the use cases overview page, where we curated multiple scenarios to help you explore the capabilities of OpenWealth API.",
                image: getStepImage('use_case_journey', '1'),
            },
            {
                id: '2',
                header: 'Step 2: Select Your Use Case and Run Sample Requests ',
                description: 'After choosing a specific use case, you can navigate through the corresponding journey. This includes running sample OpenWealth API requests and receiving responses that align with the selected scenario.',
                image: getStepImage('use_case_journey', '2'),
            },
            {
                id: '3',
                header: "Step 3: Completed the Use Case? Explore Alternative Scenarios by 'Reset Demo'",
                description: 'Upon reaching the end of the use case, you have the option to reset the journey and explore alternative scenarios associated with the use case. You also have the flexibility to reset the journey at any point within the use case.',
                image: getStepImage('use_case_journey', '3'),
            },
        ],
    },
    {
        id: 'postman',
        header: 'Test OpenWealth API via Postman',
        description: 'We offer a Postman collection for your convenience. This allows you to easily test the OpenWealth API using Postman app.',
        image: postmanIcon,
        steps: [
            {
                id: '1',
                header: 'Step 1: Download the Postman Collection',
                description: (_jsxs("div", { children: [_jsxs("p", { children: ["First of all, you need to download the Postman Collection JSON file from the", ' ', _jsx(Link, __assign({ id: "api-documentation", to: "/docs?api=order-placement", size: "large" }, { children: "API Documentation section" }))] }), _jsxs("p", __assign({ className: "mt-8" }, { children: ["Alternatively, you can directly download it using this link:", ' ', _jsx(Link, __assign({ id: "postman-download", size: "large", className: "w-fit h-fit", to: window.URL.createObjectURL(new Blob([JSON.stringify(collection, undefined, 2)], {
                                        type: 'application/json',
                                    })), download: "".concat(collection.info.name, " Postman Collection.json"), target: "_blank" }, { children: _jsxs("div", __assign({ className: "inline-flex items-center space-x-1" }, { children: [_jsx("span", { children: "Postman Collection" }), _jsx(DownloadIcon, { size: "small" })] })) }))] }))] })),
                image: getStepImage('postman', '1'),
            },
            {
                id: '2',
                header: 'Step 2: Import the OpenWealth Postman Collection',
                description: (_jsxs("span", { children: ["After downloading the OpenWealth Postman Collection file,", ' ', _jsx(Link, __assign({ className: "!inline-flex", id: "import", size: "large", to: "https://learning.postman.com/docs/getting-started/importing-and-exporting/importing-data/" }, { children: "import" })), ' ', "it into your Postman workspace. Please ensure that you use the Postman app instead of the Postman web version."] })),
                image: getStepImage('postman', '2'),
            },
            {
                id: '3',
                header: 'Step 3: Authorization and Access Token Generation',
                description: (_jsxs("div", { children: [_jsx("p", { children: "Select the 'OpenWealth Sandbox' folder (located at the top level), and from there, go to the 'Authorization' tab and click on 'Get New Access Token'. This action will prompt you to log in to the OpenWealth Sandbox." }), _jsx("p", __assign({ className: "mt-8" }, { children: "Following a successful login, the access token will be generated and will be ready to use within Postman." }))] })),
                image: getStepImage('postman', '3'),
            },
            {
                id: '4',
                header: 'Step 4: Execute Your Request Using Pre-filled Sample Values',
                description: "You can now experiment with various OpenWealth APIs by utilizing the pre-configured sample request parameters we have provided in the Postman Collection. Keep in mind that in order to obtain a successful response, it's essential to execute the request using the pre-configured request parameters.",
                image: getStepImage('postman', '4'),
            },
        ],
    },
    {
        id: 'app',
        header: 'Connect Your Application with OpenWealth API',
        description: 'You also have the possibility to integrate your application with the OpenWealth Sandbox, facilitating seamless connectivity.',
        image: appIcon,
        steps: [
            {
                id: '1',
                header: 'Connect to OpenWealth Sandbox with User Authorization',
                description: "Establishing a connection to OpenWealth Sandbox with user authorization is the first step. In the Postman Collection, you will find all the necessary information on how to set up user authorization, enabling you to connect to the Sandbox.",
                image: getStepImage('app', '1'),
            },
            {
                id: '2',
                header: 'Ensure Request Consistency in Your Client Application',
                description: "When establishing a connection to the OW API using your own client application, it is important to ensure that you send requests that mirror the sample requests provided in the Postman collection or Swagger UI precisely.",
                image: getStepImage('app', '2'),
            },
        ],
    },
];
export var AUTH_CHANNEL = new BroadcastChannel('auth');
var onSigninCallback = function (user) {
    if (user instanceof User) {
        // use AUTH_CHANNEL to notify other tabs for login
        AUTH_CHANNEL.postMessage('login');
        // redirect to the selected use case, using the id that was passed on sign in
        // or to the page where the login was initiated from
        var selectedUseCaseId = user.state;
        if (selectedUseCaseId) {
            window.location.replace("".concat(window.location.origin, "/use-cases/").concat(selectedUseCaseId));
        }
        else {
            redirectToOrigin();
        }
    }
};
export var oidcConfig = {
    authority: "".concat(process.env.KEYCLOAK_AUTHORITY).concat(process.env.KEYCLOAK_REALM),
    client_id: process.env.KEYCLOAK_CLIENT_ID,
    redirect_uri: "".concat(window.location.origin, "/login-redirect"),
    post_logout_redirect_uri: window.location.origin,
    userStore: new WebStorageStateStore({
        store: window.localStorage,
    }),
    onSigninCallback: onSigninCallback,
};
