var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Tabs } from '@s8/react-component-library';
import DocsList from '../../UI/DocsList';
import DocsQuickStart from './DocsQuickStart';
import { useNavigate, useParams } from 'react-router-dom';
export default function DocsOverview() {
    // use the location's state to determine which tab to show on load
    var overviewTabValue = useParams().overviewTabValue;
    var navigate = useNavigate();
    var _a = useState(overviewTabValue || 'api-categories'), value = _a[0], setValue = _a[1];
    var tabs = [
        {
            id: 'api-categories',
            label: 'API Categories',
        },
        { id: 'quick-start', label: 'Quick Start' },
    ];
    var handleChange = function (id) {
        setValue(id);
        navigate("/docs/".concat(id));
    };
    return (_jsxs("div", { children: [_jsx(Tabs, { value: value, tabs: tabs, size: "medium", className: "w-fit my-1 mx-8", onChange: function (id) { return handleChange(id); } }), _jsx("div", __assign({ className: "mt-7 mb-96 ml-8" }, { children: value === 'api-categories' ? (_jsx(DocsList, { style: "gap-x-14", itemWidth: "w-[300px]" })) : (_jsx(DocsQuickStart, {})) }))] }));
}
