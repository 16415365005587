var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardAction, CardMedia, CardContent, CardHeader, } from '@s8/react-component-library';
export default function ListItem(props) {
    var name = props.name, description = props.description, image = props.image, label = props.label, itemWidth = props.itemWidth, onClick = props.onClick, onContextMenu = props.onContextMenu, linkProps = props.linkProps;
    return (_jsxs(Card, __assign({ className: itemWidth }, { children: [_jsx(CardMedia, { image: image, aspectRatio: "16/9" }), _jsx(CardHeader, { size: "large", header: name, className: "gap-x-8" }), _jsx(CardContent, __assign({ className: "grow" }, { children: _jsx("p", __assign({ className: "clip-text text-body-2 text-neutral-4", "data-lines": 2 }, { children: description })) })), _jsx(CardAction, { children: _jsx(Button, __assign({ type: "button", size: "medium", className: "bg-gradient-coral", actionType: "default", variant: "primary", linkProps: linkProps, onClick: onClick, onContextMenu: onContextMenu }, { children: label })) })] })));
}
