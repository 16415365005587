import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthUtils } from '../../../utils/authUtils';
export default function SecuredRoute(_a) {
    var children = _a.children;
    var _b = useAuthUtils(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, login = _b.login;
    var id = useParams().id;
    useEffect(function () {
        if (!isLoading && !isAuthenticated) {
            login({ state: id });
        }
    }, [isLoading, isAuthenticated]);
    return !isLoading ? isAuthenticated ? children : _jsx(Navigate, { to: "/use-cases" }) : _jsx("div", {});
}
