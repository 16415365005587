var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Header from './components/UI/Header';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import About from './pages/About';
import Docs from './pages/Docs';
import Scroller from './components/UI/Scroller';
import UseCases from './pages/UseCases';
import SecuredRoute from './components/UseCases/SecuredRoute';
import UseCaseItem from './components/UseCases/UseCaseItem';
import Footer from './components/UI/Footer';
import LoginRedirect from './pages/LoginRedirect';
import { HOME_PAGE_PATH } from './config';
import TermsAndConditions from './pages/T&C';
function App() {
    return (_jsx("div", __assign({ className: "w-full flex flex-col min-h-screen" }, { children: _jsxs(BrowserRouter, { children: [_jsx(Header, {}), _jsx(Scroller, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Navigate, { to: HOME_PAGE_PATH, replace: true }) }), _jsx(Route, { path: "/about", index: true, element: _jsx(About, {}) }), _jsx(Route, { path: "/docs", index: true, element: _jsx(Docs, {}) }), _jsx(Route, { path: "/docs/:overviewTabValue", index: true, element: _jsx(Docs, {}) }), _jsx(Route, { path: "/use-cases", index: true, element: _jsx(UseCases, {}) }), _jsx(Route, { path: "/terms", index: true, element: _jsx(TermsAndConditions, {}) }), _jsx(Route, { path: "/use-cases/:id", element: _jsx(SecuredRoute, { children: _jsx(UseCaseItem, {}) }) }), _jsx(Route, { path: "/login-redirect", index: true, element: _jsx(LoginRedirect, {}) })] }) }), _jsx(Footer, {})] }) })));
}
export default App;
