var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import ListItem from '../../UI/ListItem';
import { DOCS_LIST } from '../../../config';
export default function DocsList(_a) {
    var style = _a.style, itemWidth = _a.itemWidth;
    var docs = DOCS_LIST.docs;
    var renderedDocItems = docs.map(function (doc) {
        var id = doc.id, item = doc.item, image = doc.image;
        return (_jsx(ListItem, { id: id, name: item.info.title, description: item.info.description, image: image, label: "Read the docs", itemWidth: itemWidth, linkProps: { to: "/docs?api=".concat(id) } }, id));
    });
    return _jsx("div", __assign({ className: clsx('flex', style) }, { children: renderedDocItems }));
}
