var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Button, Tab, Tabs } from '@s8/react-component-library';
import ReadOnlyInput from './ReadOnlyInput';
import CodeEditor from './CodeEditor';
import { useState, useEffect, useRef } from 'react';
import { SpinnerIcon } from '../../../../UI/Icons';
import { useAppSelector } from '../../../../../redux/hook';
import { selectResponseStatus } from '../../../../../redux/reducers/UseCaseStatesSlice';
export function RequestContainer(_a) {
    var transition = _a.transition, response = _a.response, onSubmit = _a.onSubmit;
    var responseStatus = useAppSelector(selectResponseStatus);
    var _b = useState('headers'), value = _b[0], setValue = _b[1];
    var scrollToRef = useRef(null);
    var getCodeToShow = function () {
        switch (value) {
            case 'headers':
                return JSON.stringify(transition.requestHeaders, null, 2);
            case 'query-parameters':
                if (transition.requestParameters) {
                    return JSON.stringify(transition.requestParameters, null, 2);
                }
                else {
                    return '{}';
                }
            case 'request-body':
                if (transition.payload) {
                    return JSON.stringify(JSON.parse(transition.payload), null, 2);
                }
                else {
                    return '{}';
                }
            default:
                return '{}';
        }
    };
    var _c = useState(getCodeToShow()), codeToShow = _c[0], setCodeToShow = _c[1];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var handleChange = function (id) {
        setValue(id);
    };
    useEffect(function () {
        var codeToSet = getCodeToShow();
        setCodeToShow(codeToSet);
    }, [value, transition]);
    useEffect(function () {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [open]);
    var handleSubmit = function () {
        onSubmit();
    };
    useEffect(function () {
        if (response)
            setOpen(true);
    }, [response]);
    var tabs = [
        {
            id: 'headers',
            label: 'Headers',
        },
        { id: 'query-parameters', label: 'Query Parameters' },
        {
            id: 'request-body',
            label: 'Request Body',
        },
    ];
    return (_jsxs("div", { children: [_jsxs("form", __assign({ onSubmit: handleSubmit, className: "flex space-x-12 items-center" }, { children: [_jsxs("div", __assign({ className: "flex space-x-3" }, { children: [_jsx(ReadOnlyInput, { label: "Method", value: transition.method, style: "w-24" }), _jsx(ReadOnlyInput, { label: "URL", value: "".concat(process.env.API_URL, "/api").concat(transition.endpoint), style: "text-tertiary w-96", copyIcon: true })] })), _jsx(Button, __assign({ type: "button", size: "medium", actionType: "default", variant: "primary", className: clsx('whitespace-nowrap', { 'bg-gradient-coral': !open }), endIcon: responseStatus === 'loading' ? _jsx(SpinnerIcon, { style: "w-4 h-4" }) : undefined, disabled: open, onClick: handleSubmit }, { children: "Run it" }))] })), _jsx(Tabs, { value: value, tabs: tabs, size: "medium", className: "w-fit my-2.5", onChange: function (id) { return handleChange(id); } }), _jsx(CodeEditor, { code: codeToShow, language: "json", fontSize: 12, width: 600, readOnly: true }), open && (_jsxs("div", __assign({ className: "mt-10", ref: scrollToRef }, { children: [_jsx(Tab, { tab: { id: 'response', label: 'Response' }, size: "medium", className: "w-fit mb-2.5", active: true }), _jsx(CodeEditor, { code: JSON.stringify(response, null, 2), language: "json", fontSize: 12, width: 600, readOnly: true })] })))] }));
}
