var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, RadioButton, Button } from '@s8/react-component-library';
import { RequestContainer } from './RequestContainer';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { fetchUseCaseStateResponse } from '../../../../redux/thunks/UseCaseStatesService';
import { useAuth } from 'react-oidc-context';
import { selectResponseStatus } from '../../../../redux/reducers/UseCaseStatesSlice';
export function UseCaseStateItem(_a) {
    var _b;
    var state = _a.state, onContinue = _a.onContinue, shouldScrollTo = _a.shouldScrollTo;
    var _c = useState(false), disabled = _c[0], setDisabled = _c[1];
    var _d = useState(true), open = _d[0], setOpen = _d[1];
    var _e = useState(false), shown = _e[0], setShown = _e[1];
    var _f = useState(state.transitions[0]), selectedTransition = _f[0], setSelectedTransition = _f[1];
    var responseStatus = useAppSelector(selectResponseStatus);
    var scrollToRef = useRef(null);
    var dispatch = useAppDispatch();
    var auth = useAuth();
    useEffect(function () {
        if (scrollToRef.current) {
            shouldScrollTo && scrollToRef.current.scrollIntoView({ block: 'center' });
        }
    }, []);
    var handleTransitionChange = function (id) {
        var selectedTransitionToSet = state.transitions.find(function (transition) { return transition.id === id; });
        setSelectedTransition(selectedTransitionToSet);
    };
    var submitAPIRequest = function () {
        var _a;
        setDisabled(true);
        setShown(true);
        dispatch(fetchUseCaseStateResponse({
            stateId: state.id,
            transition: selectedTransition,
            // the user will be provided since the component is only accessed by authenticated users
            accessToken: (_a = auth.user) === null || _a === void 0 ? void 0 : _a.access_token,
        }));
    };
    var handleContinue = function () {
        onContinue();
        setOpen(false);
        setShown(false);
    };
    var continueButton = (_jsx("div", __assign({ className: "flex space-x-5 absolute bottom-0 animate-fade-in" }, { children: _jsx(Button, __assign({ size: "medium", actionType: "default", variant: "primary", className: responseStatus === 'failed' ? '' : 'bg-gradient-coral', disabled: responseStatus === 'failed', onClick: function () { return handleContinue(); } }, { children: "Continue" })) })));
    var renderedOptions = state.transitions.length > 1 ? (state.transitions.map(function (transition) {
        var checked = transition.id === selectedTransition.id;
        return (_jsx(RadioButton, __assign({ id: "".concat(transition.id), value: "".concat(transition.id), size: "large", checked: checked, disabled: disabled && !checked, onChange: function () { return handleTransitionChange(transition.id); } }, { children: transition.name }), transition.id));
    })) : (_jsx(RadioButton, __assign({ id: "".concat(selectedTransition.id), value: "".concat(selectedTransition.id), size: "large", checked: true, onChange: function () { } }, { children: selectedTransition.name }), selectedTransition.id));
    var stateOptions = (_jsxs("div", { children: [_jsxs("div", __assign({ className: "my-12 space-y-6" }, { children: [_jsx("div", __assign({ className: "text-coral-40 mb-3" }, { children: "Available actions:" })), ((_b = state.transitions) === null || _b === void 0 ? void 0 : _b.length) > 0 && renderedOptions, _jsx("div", { children: selectedTransition.description.split('\n').map(function (par) {
                            return (_jsx("p", __assign({ className: "mb-3" }, { children: par }), par));
                        }) })] })), shown && continueButton] }));
    var content = (_jsxs("div", __assign({ className: "mt-3 grid grid-cols-2 space-x-6" }, { children: [_jsxs("div", __assign({ className: "relative" }, { children: [state.description, stateOptions] })), _jsx(RequestContainer, { transition: selectedTransition, response: state.response, onSubmit: submitAPIRequest })] })));
    return (_jsx("div", __assign({ ref: scrollToRef }, { children: _jsx(Accordion, __assign({ headerProps: { header: state.name }, toggleProps: {
                size: 'large',
            }, open: open }, { children: content })) })));
}
