import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router';
export default function Scroller(_a) {
    var children = _a.children;
    var location = useLocation();
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [location]);
    return _jsx(_Fragment, { children: children });
}
