var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@s8/react-component-library';
export var TwitterIcon = function () {
    return (_jsx(SvgIcon, __assign({ size: "xlarge", stroke: "white" }, { children: _jsx("path", { d: "M16.0007 11.0004C16.0008 9.85156 16.3964 8.73783 17.1211 7.84641C17.8457 6.95498 18.8552 6.34023 19.9797 6.10551C21.1043 5.87078 22.2754 6.0304 23.2961 6.55752C24.3168 7.08464 25.125 7.94712 25.5846 8.99996L30.0007 9L25.9679 13.0328C25.7053 17.089 23.9088 20.8933 20.9433 23.6731C17.9777 26.4529 14.0654 27.9999 10.0007 28C6.00068 28 5.00068 26.5 5.00068 26.5C5.00068 26.5 9.00068 25 11.0007 22C11.0007 22 3.00068 18 5.00068 7C5.00068 7 10.0007 12 15.999 13L16.0007 11.0004Z", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
