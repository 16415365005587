var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import ListItem from '../../UI/ListItem';
import { Card, CardMedia } from '@s8/react-component-library';
export default function UseCaseList(_a) {
    var useCases = _a.useCases, style = _a.style, isLoading = _a.isLoading;
    var getImage = function (id) {
        try {
            var image = require("./assets/".concat(id, ".png"));
            return image;
        }
        catch (error) {
            return require("./assets/unknown.png");
        }
    };
    var setUseCaseUIOrigin = function () {
        localStorage.setItem('useCaseOrigin', 'buttonClick');
    };
    var loadingUseCases = __spreadArray([], Array(3), true).map(function (e, index) {
        return (_jsx(Card, __assign({ className: "w-[400px]" }, { children: _jsx(CardMedia, { image: require("./assets/unknown.png") }) }), index));
    });
    var renderedUseCaseItems = useCases.map(function (useCase) {
        return (_jsx(ListItem, { id: useCase.id.toString(), name: useCase.name, description: useCase.description, image: getImage(useCase.id), label: "View Journey", itemWidth: "w-[400px]", linkProps: { to: "/use-cases/".concat(useCase.id) }, onClick: setUseCaseUIOrigin }, useCase.id));
    });
    return (_jsx("div", __assign({ className: clsx('grid grid-cols-3 gap-y-16', { 'animate-pulse': isLoading }, style) }, { children: isLoading ? loadingUseCases : renderedUseCaseItems })));
}
