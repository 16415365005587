var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import UseCaseList from '../../components/UseCases/UseCaseList';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { selectUseCases, selectUseCasesStatus } from '../../redux/reducers/UseCasesSlice';
import { useEffect, useRef } from 'react';
import { fetchUseCases } from '../../redux/thunks/UseCasesService';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import ErrorHandler from '../../components/UI/ErrorHandler';
import Loading from '../../components/UI/Loading';
export default function UseCases() {
    document.title = 'Use Cases - OpenWealth API Sandbox';
    var auth = useAuth();
    var useCasesStatus = useAppSelector(selectUseCasesStatus);
    var useCases = useAppSelector(selectUseCases);
    var hasFetchedUseCases = useRef(false);
    var dispatch = useAppDispatch();
    var getUseCases = function () {
        dispatch(fetchUseCases());
    };
    // fetch data when the component mounts
    useEffect(function () {
        // the if else is to avoid twice fetching in strict mode and during sign-in redirect
        if (!hasAuthParams() && !hasFetchedUseCases.current) {
            getUseCases();
            hasFetchedUseCases.current = true;
        }
    }, []);
    var content = (_jsx(_Fragment, { children: auth.isLoading ? (_jsx(Loading, { header: "Redirecting to login" })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "w-full text-heading-2 border-b border-minimal pl-8 pb-7 mt-7" }, { children: "OpenWealth API Use Cases" })), useCasesStatus === 'failed' && _jsx(ErrorHandler, {}), _jsx("div", __assign({ className: "flex justify-center" }, { children: _jsx(UseCaseList, { useCases: useCases, style: "gap-x-14 mt-7 mb-96 ml-8", isLoading: useCasesStatus === 'loading' }) }))] })) }));
    return _jsx("div", __assign({ className: "flex flex-col justify-center" }, { children: content }));
}
