var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useState } from 'react';
export var CopyIcon = function (_a) {
    var style = _a.style, textToCopy = _a.textToCopy;
    var _b = useState(false), isClicked = _b[0], setIsClicked = _b[1];
    var handleClick = function () {
        setIsClicked(true);
        navigator.clipboard.writeText(textToCopy);
        setTimeout(function () {
            setIsClicked(false);
        }, 1000);
    };
    return (_jsxs("svg", __assign({ className: clsx('cursor-pointer rounded', style, { 'animate-icon-clicked': isClicked }), viewBox: "0 0 32 32", fill: "none", onClick: handleClick }, { children: [_jsx("path", { d: "M26.9989 22.9994V4.99927H8.99805", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M22.9988 8.99951H4.99805V26.9995H22.9988V8.99951Z", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
};
