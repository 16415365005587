var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
export var SpinnerIcon = function (_a) {
    var style = _a.style;
    return (_jsxs("svg", __assign({ className: clsx('animate-spin', style), viewBox: "-20 -20 240 240", fill: "none" }, { children: [_jsxs("defs", { children: [_jsxs("linearGradient", __assign({ id: "spinner-secondHalf" }, { children: [_jsx("stop", { offset: "0%", stopOpacity: "0", stopColor: "currentColor" }), _jsx("stop", { offset: "100%", stopOpacity: "0.5", stopColor: "currentColor" })] })), _jsxs("linearGradient", __assign({ id: "spinner-firstHalf" }, { children: [_jsx("stop", { offset: "0%", stopOpacity: "1", stopColor: "currentColor" }), _jsx("stop", { offset: "100%", stopOpacity: "0.5", stopColor: "currentColor" })] }))] }), _jsxs("g", __assign({ strokeWidth: "28" }, { children: [_jsx("path", { stroke: "url(#spinner-secondHalf)", d: "M 4 100 A 96 96 0 0 1 196 100" }), _jsx("path", { stroke: "url(#spinner-firstHalf)", d: "M 196 100 A 96 96 0 0 1 4 100" })] }))] })));
};
