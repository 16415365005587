var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { QUICK_START_LIST } from '../../../../config';
import QuickStartItem from '../../../UI/QuickStartItem/QuickStartItem';
import { useAuthUtils } from '../../../../utils/authUtils';
export default function DocsQuickStart() {
    var login = useAuthUtils().login;
    var renderedQuickStartItems = QUICK_START_LIST.map(function (item) {
        return (_jsx(QuickStartItem, { id: item.id, header: item.header, description: item.description, image: item.image, steps: item.steps }, item.id));
    });
    return (_jsxs("div", { children: [_jsx("p", __assign({ className: "text-heading-4 text-brand-base" }, { children: "Getting started" })), _jsxs("div", { children: [_jsx("p", __assign({ className: "text-heading-5 mt-8" }, { children: "Register" })), _jsxs("div", __assign({ className: "grid grid-cols-2 gap-x-16" }, { children: [_jsxs("div", __assign({ className: "flex flex-col space-y-1 mt-4" }, { children: [_jsx("p", { children: "New to OpenWealth Sandbox?" }), _jsx("p", __assign({ className: "text-secondary" }, { children: "Signing up is easy. Just fill out a registration form." })), _jsxs(Link, __assign({ to: "#", className: "block text-link-lg text-link", onClick: function () { return login(); } }, { children: ["Sign Up ", '>'] }))] })), _jsxs("div", __assign({ className: "flex flex-col space-y-1 mt-4" }, { children: [_jsx("p", { children: "Already registered?" }), _jsx("p", __assign({ className: "text-secondary" }, { children: "Login to explore the OpenWealth APIs solution." })), _jsxs(Link, __assign({ to: "#", className: "text-link-lg text-link", onClick: function () { return login(); } }, { children: ["Login ", '>'] }))] }))] }))] }), _jsxs("div", { children: [_jsx("p", __assign({ className: "text-heading-5 mt-8" }, { children: "Explore What You Can Do with OpenWealth Sandbox" })), _jsx("p", __assign({ className: "text-heading-5 mt-8" }, { children: "Here are several features you can explore with the OpenWealth Sandbox:" })), _jsx("div", __assign({ className: "grid grid-cols-2 gap-x-16 gap-y-5 mt-8" }, { children: renderedQuickStartItems }))] })] }));
}
