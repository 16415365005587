var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@s8/react-component-library';
import { useNavigate } from 'react-router';
export default function ErrorHandler() {
    var navigate = useNavigate();
    return (_jsxs("div", __assign({ className: "flex flex-col items-center justify-between space-y-4 mt-7" }, { children: [_jsx("div", __assign({ className: "text-heading-3" }, { children: "Something went wrong" })), _jsx("div", { children: "An error occurred in the application and your page could not be served." }), _jsx(Button, __assign({ type: "button", size: "medium", className: "bg-gradient-coral", actionType: "default", variant: "primary", onClick: function () { return navigate(0); } }, { children: "Refresh" }))] })));
}
