var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import Doc from '../../components/Docs/Doc';
import DocsTabs from '../../components/Docs/DocsTabs';
import DocsOverview from '../../components/Docs/DocsOverview';
import _ from 'lodash';
export default function Docs() {
    var searchParams = useSearchParams()[0];
    var docId = searchParams === null || searchParams === void 0 ? void 0 : searchParams.get('api');
    document.title = "".concat(docId ? _.startCase(docId.split('-').join(' ')) : 'Docs', " - OpenWealth API Sandbox");
    var content = (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(DocsTabs, {}), docId ? _jsx(Doc, { docId: docId }) : _jsx(DocsOverview, {})] })));
    return (_jsxs("div", __assign({ className: "flex flex-col justify-center" }, { children: [_jsx("div", __assign({ className: "w-full text-heading-2 border-b border-minimal pl-8 pb-7 mt-7" }, { children: "OpenWealth API Documentation" })), content] })));
}
