var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentState, fetchUseCaseStateResponse } from '../thunks/UseCaseStatesService';
var initialState = {
    stateStatus: 'idle',
    responseStatus: 'idle',
    error: null,
    data: [],
    hasReachedEndState: false,
};
export var useCaseStatesSlice = createSlice({
    name: 'useCaseState',
    initialState: initialState,
    reducers: {
        resetStates: function () { return initialState; },
    },
    extraReducers: function (builder) {
        // ------- fetching current state -------
        builder.addCase(fetchCurrentState.pending, function (state) {
            state.stateStatus = 'loading';
            state.error = null;
        });
        builder.addCase(fetchCurrentState.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.stateStatus = 'success';
            state.data.push(__assign({}, payload)); // add the new state to the list
        });
        builder.addCase(fetchCurrentState.rejected, function (state, _a) {
            var payload = _a.payload;
            if (payload) {
                state.error = payload.message;
                if (payload.message === 'Reached the end of states.') {
                    state.hasReachedEndState = true; // set the corresponding state to true
                }
            }
            state.stateStatus = 'failed';
        });
        // ------- fetching response for current state -------
        builder.addCase(fetchUseCaseStateResponse.pending, function (state) {
            state.responseStatus = 'loading';
            state.error = null;
        });
        builder.addCase(fetchUseCaseStateResponse.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.responseStatus = 'success';
            // add the response to the corresponding state
            state.data = state.data.map(function (state) {
                if (state.id === payload.stateId) {
                    return __assign(__assign({}, state), { response: payload.response });
                }
                return state;
            });
        });
        builder.addCase(fetchUseCaseStateResponse.rejected, function (state, _a) {
            var payload = _a.payload;
            if (payload)
                state.error = payload.message;
            // add service unavailable response to the last available state
            state.data[state.data.length - 1].response = {
                message: 'Service is currently unavailable. Please try again later.',
                status: 'error',
            };
            state.responseStatus = 'failed';
        });
    },
});
export var selectStateStatus = function (state) { return state.useCaseStates.stateStatus; };
export var selectResponseStatus = function (state) { return state.useCaseStates.responseStatus; };
export var selectHasReachedEndState = function (state) {
    return state.useCaseStates.hasReachedEndState;
};
export var selectUseCaseStates = function (state) { return state.useCaseStates.data; };
export var resetStates = useCaseStatesSlice.actions.resetStates;
export default useCaseStatesSlice.reducer;
