var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import { Banner, Badge, Button, CheckIcon } from '@s8/react-component-library';
import { UseCaseStateItem } from './UseCaseStateItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { resetStates, selectHasReachedEndState, selectUseCaseStates, } from '../../../redux/reducers/UseCaseStatesSlice';
import { fetchCurrentState, resetState } from '../../../redux/thunks/UseCaseStatesService';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { selectUseCases } from '../../../redux/reducers/UseCasesSlice';
import { fetchUseCases } from '../../../redux/thunks/UseCasesService';
import { useAuth } from 'react-oidc-context';
import Loading from '../../UI/Loading';
import axios from 'axios';
export default function UseCaseItem() {
    var _this = this;
    var id = useParams().id;
    document.title = "".concat(id ? id.toUpperCase() : 'Use Cases', " - OpenWealth API Sandbox");
    var useCases = useAppSelector(selectUseCases);
    var _a = useState(undefined), useCase = _a[0], setUseCase = _a[1];
    var _b = useState(0), counter = _b[0], setCounter = _b[1]; // for scrolling to latest state
    var _c = useState(true), isInitialState = _c[0], setIsInitialState = _c[1];
    var _d = useState(false), isReset = _d[0], setIsReset = _d[1];
    var states = useAppSelector(selectUseCaseStates);
    var hasReachedEndState = useAppSelector(selectHasReachedEndState);
    var hasFetchedUseCases = useRef(false);
    var auth = useAuth();
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    useEffect(function () {
        if (useCases.length === 0) {
            // the if else is to avoid twice fetching in strict mode
            if (!hasFetchedUseCases.current) {
                dispatch(fetchUseCases());
                hasFetchedUseCases.current = true;
            }
        }
    }, []);
    var getInitialState = function () {
        // the user must reset their state before first fetchCurrentState() call
        dispatch(resetState({ accessToken: auth.user.access_token })).then(function () {
            getCurrentState();
            setIsInitialState(false);
        });
    };
    var getCurrentState = function () {
        dispatch(fetchCurrentState({
            useCaseId: useCase.id,
            // the user will be provided since the component is only accessed by authenticated users
            accessToken: auth.user.access_token,
        })).then(function () {
            setCounter(counter + 1);
        });
    };
    var resetUseCaseStates = function () {
        dispatch(resetStates());
    };
    var resetDemo = function () {
        resetUseCaseStates();
        setCounter(0);
        setIsReset(true);
    };
    useEffect(function () {
        setUseCase(useCases.find(function (useCase) { return useCase.id === id; }));
    }, [useCases]);
    useEffect(function () {
        var origin = localStorage.getItem('useCaseOrigin');
        var addUseCaseView = function (id) { return __awaiter(_this, void 0, Promise, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('sending metrics');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, axios.put("".concat(process.env.API_URL, "/metrics/useCase/addView/").concat(id), {}, {
                                headers: {
                                    Authorization: "Bearer ".concat(auth.user.access_token),
                                },
                                timeout: 6000,
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (useCase) {
            getInitialState();
            if (origin) {
                if (origin === 'buttonClick') {
                    addUseCaseView(useCase.id);
                    localStorage.removeItem('useCaseOrigin');
                }
            }
        }
        // reset the states when the component unmounts
        return function () {
            resetUseCaseStates();
        };
    }, [useCase]);
    // side effect to reset
    useEffect(function () {
        if (isReset) {
            getInitialState();
            setIsReset(false);
        }
    }, [isReset]);
    var handleContinue = function () {
        getCurrentState();
    };
    var handleNavigation = function () {
        navigate({
            pathname: '/use-cases',
        });
    };
    var renderedStates = states.map(function (state, index) {
        if (state.transitions.length >= 1)
            return (_jsx(UseCaseStateItem, { state: state, onContinue: handleContinue, shouldScrollTo: counter !== 0 && index === counter }, "".concat(useCase === null || useCase === void 0 ? void 0 : useCase.id, "_").concat(state.id)));
    });
    var buttonSet = (_jsxs("div", __assign({ className: "flex space-x-5 ml-auto animate-fade-in" }, { children: [_jsx(Button, __assign({ size: "medium", actionType: "default", variant: "primary", className: "bg-gradient-coral", onClick: function () { return handleNavigation(); } }, { children: "Back to Use Cases" })), _jsx(Button, __assign({ size: "medium", variant: "secondary", actionType: "destructive", onClick: function () { return resetDemo(); } }, { children: "Reset Demo" }))] })));
    // show actions container after the first state has been completed
    var actionsContainer = states.length >= 1 && states[0].response && (_jsxs("div", __assign({ className: "flex my-5 items-center" }, { children: [hasReachedEndState && (_jsxs("div", __assign({ className: "flex space-x-5 p-4" }, { children: [_jsx(CheckIcon, { className: "bg-success-accent rounded-full text-white", size: "large" }), _jsx("span", { children: "You have reached the end of this use case. Please reset the use case or go back to use cases list." })] }))), buttonSet] })));
    var createStatusBar = function (labels) {
        var colors = {
            'Order Placement API': 'blue',
            'Custody Services API': 'green',
            'Customer Management API': 'yellow',
        };
        return labels.map(function (label) {
            return _jsx(Badge, { size: "large", allCaps: false, label: label, color: colors[label] }, label);
        });
    };
    var renderedUseCase = useCase && (_jsxs("div", { children: [_jsx(Banner, { header: useCase.name, description: useCase.description.split('\n').map(function (par) {
                    return (_jsx("p", __assign({ className: "mb-3" }, { children: par }), par));
                }), style: "mt-7", statusBar: createStatusBar(useCase.apiCategories) }), _jsx("div", __assign({ className: "accordion-group mt-5" }, { children: renderedStates })), actionsContainer] }));
    return (_jsxs("div", __assign({ className: "mx-8 mb-96" }, { children: [(isInitialState || isReset) && (_jsx(Loading, { header: "Preparing your use case", subtitle: "Hold tight, we're getting your use case ready." })), !isInitialState && !isReset && renderedUseCase] })));
}
