import { useAuth } from 'react-oidc-context';
import { AUTH_CHANNEL, HOME_PAGE_PATH } from '../config';
export function useAuthUtils() {
    var _a = useAuth(), user = _a.user, isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, signinRedirect = _a.signinRedirect, signoutRedirect = _a.signoutRedirect, removeUser = _a.removeUser;
    var login = function (args) {
        localStorage.setItem('loginOrigin', window.location.pathname);
        signinRedirect(args);
    };
    var logout = function (args) {
        AUTH_CHANNEL.postMessage('logout');
        signoutRedirect(args);
    };
    var addBroadcastChannelListeners = function () {
        AUTH_CHANNEL.addEventListener('message', function (event) {
            switch (event.data) {
                // Remove user when logout happens in a different tab
                case 'logout':
                    removeUser();
                    break;
                // Reload the page when login happens in a different tab
                case 'login':
                    window.location.reload();
                    break;
                default:
                    break;
            }
        });
    };
    return { user: user, isAuthenticated: isAuthenticated, isLoading: isLoading, login: login, logout: logout, addBroadcastChannelListeners: addBroadcastChannelListeners };
}
export function redirectToOrigin() {
    var pathName = window.localStorage.getItem('loginOrigin');
    if (!pathName) {
        window.localStorage.setItem('loginOrigin', HOME_PAGE_PATH);
    }
    window.location.replace("".concat(window.location.origin).concat(pathName || HOME_PAGE_PATH));
}
